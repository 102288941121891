import Axios from 'axios';
import { isNowIncludeTimes, to2Digit } from './convertUtil';


/**
* 초를 HH:MM 포맷으로 변경한다.
* @param seconds
* @returns {string}
*/
export const toTimeStamp = (seconds) => {
  let sysdate = new Date(seconds);
  let hh = sysdate.getHours();


  hh = (hh < 10) ? ('0' + hh) : hh;
  let mm = sysdate.getMinutes() < 10 ? ('0' + sysdate.getMinutes()) : sysdate.getMinutes();

  if (hh > 12) {
    hh -= 12;
    //hh = (hh < 10) ? ('0' + hh) : hh;
    hh = "오후 " + hh;
  } else if (hh == 12) {
    hh = "오후 " + hh;
  } else {
    //hh = (hh < 10) ? ('0' + hh) : hh;
    hh = "오전 " + hh;
  }

  let result = hh + ':' + mm;

  return result;
}

/**
 * 이전 스케줄 항목을 가져옴
 */
export const getPreviousScheduleItem = (scheduleArray, index) => {
  const simpleObj = {};
  const pre2PlanIndex = parseInt(index - 2);
  const pre1PlanIndex = parseInt(index - 1);

  simpleObj.homepage_url = null;
  simpleObj.starttime = scheduleArray[pre2PlanIndex]?.starttime || scheduleArray[pre1PlanIndex]?.starttime || null;
  simpleObj.endtime = scheduleArray[pre2PlanIndex]?.endtime || scheduleArray[pre1PlanIndex]?.starttime || null;
  simpleObj.title = scheduleArray[pre2PlanIndex]?.title || scheduleArray[pre2PlanIndex]?.title || '정보없음';

  return simpleObj;
}

/** 라이브 편성 정보 불러오기.
 * @function
 * @param string
 * @returns {array}
 */
export const getLivePlanArray = async (channelId, isMobile) => {
  const channelType = getLiveChannelType(channelId);
  
  try {
    if (channelType === '24tv') {
      return await get24tvPlan(channelId, isMobile);
    } else {
      return await getTvPlan(channelId, isMobile);
    }
  } catch (error) {
    console.error('getLivePlanArray 오류:', error);
    return [];
  }
};
// 24시간 TV 편성 정보
async function get24tvPlan(channelId, isMobile) {
  const virtualScheduleData = await Axios.get(`${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE_24TV}/${channelId}`);
  const scheduleArray = virtualScheduleData?.data?.list || [];
  
  scheduleArray.forEach(obj => {
    obj.starttime_date = `${obj.date.substr(0, 10)}T${obj.date.substr(11)}`;
  });

  return processSchedule(scheduleArray, channelId, isMobile);
}
// 일반 TV 편성 정보
async function getTvPlan(channelId, isMobile) {
  const [yesterday, today, tomorrow] = getDates();
  const plans = await Promise.all([
    getPlanForDate(channelId, yesterday),
    getPlanForDate(channelId, today),
    getPlanForDate(channelId, tomorrow)
  ]);
  
  const array3daysPlan = plans.flat();
  return processSchedule(array3daysPlan, channelId, isMobile);
}
// 어제, 오늘, 내일 날짜를 반환
function getDates() {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return [yesterday, today, tomorrow];
}

async function getPlanForDate(channelId, date) {
  const planAPI = toPlanAPIUrl(channelId, date.getFullYear(), date.getMonth() + 1, date.getDate());
  const response = await Axios.get(planAPI);
  return processPlanData(response.data, date);
}
// 편성 데이터를 처리
function processPlanData(data, date) {
  return data.map((obj, index, array) => {
    const temp = obj.start_time;
    const [hours, minutes] = temp.split(':').map(Number);
    
    if (hours >= 24) {
      const nextDay = new Date(date);
      nextDay.setDate(nextDay.getDate() + 1);
      obj.starttime_date = new Date(nextDay.setHours(hours - 24, minutes));
      obj.starttime = `${String(hours - 24).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    } else {
      obj.starttime_date = new Date(date.setHours(hours, minutes));
      obj.starttime = temp;
    }
    
    if (index === array.length - 1) {
      obj.endtime = '';
    } else {
      const nextItem = array[index + 1];
      obj.endtime = nextItem.start_time;
    }
    
    return obj;
  });
}

function processSchedule(scheduleArray, channelId, isMobile) {
  const plan = [];
  const korCurrent = getKoreanTime();
  const currentIndex = findCurrentIndex(scheduleArray, korCurrent);

  if (currentIndex !== -1) {
    const startIndex = Math.max(0, currentIndex - 1);
    const endIndex = Math.min(scheduleArray.length, startIndex + 15);

    for (let i = startIndex; i < endIndex; i++) {
      const simpleObj = createScheduleItem(scheduleArray[i], scheduleArray[i-1], i === currentIndex);
      
      if (shouldAddToPlan(channelId, isMobile, i - startIndex)) {
        plan.push(simpleObj);
      }
    }
  }
  
  return plan;
}

function findCurrentIndex(scheduleArray, currentTime) {
  return scheduleArray.findIndex((item, index) => {
    const startTime = new Date(item.starttime_date);
    const endTime = scheduleArray[index + 1]?.starttime_date 
      ? new Date(scheduleArray[index + 1].starttime_date) 
      : null;
    return startTime && endTime && currentTime >= startTime && currentTime < endTime;
  });
}

function createScheduleItem(currentItem, previousItem, isCurrent) {
  const simpleObj = createJSON(currentItem, previousItem);
  
  if (simpleObj.starttime_date instanceof Date) {
    simpleObj.starttime_date = simpleObj.starttime_date.toISOString();
  }
  
  if (isCurrent) {
    simpleObj.current = true;
  }
  
  return simpleObj;
}


function getKoreanTime() {
  const current = new Date();
  const utc = current.getTime() + (current.getTimezoneOffset() * 60 * 1000);
  const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
  return new Date(utc + KR_TIME_DIFF);
}

function createJSON(currentItem, previousItem) {
  return {
    ...currentItem,
    homepage_url: currentItem.homepage_url || previousItem?.homepage_url || null,
    thumbnail: currentItem.thumbnail || previousItem?.thumbnail || null,
    starttime: currentItem.starttime || previousItem?.starttime || '',
    endtime: currentItem.endtime || previousItem?.endtime || '',
    title: currentItem.title || previousItem?.title || '정보없음'
  };
}

// 현재 라이브 편성 반영
function shouldSetCurrent(channelId, index, isCurrent, plan) {
  return (getLiveChannelType(channelId) === '24tv' && index === 1 && !isCurrent) ||
         (getLiveChannelType(channelId) !== '24tv' && plan.length === 0 && !isCurrent);
}

function shouldAddToPlan(channelId, isMobile, index) {
  return getLiveChannelType(channelId) === '24tv' ? (!isMobile || index !== 0) : true;
}

/** 채널타입 확인.
 * @function
 * @param string
 * @returns {string}
 */
export const getLiveChannelType = (channelId) => {
  let type;
  switch (channelId) {
    case 'S01':
    case 'S02':
    case 'S03':
    case 'S04':
    case 'S05':
    case 'S06':
    case 'S09':
    case 'S10':
    case 'S11':
    case 'S12':
    case 'S20':
    case 'EVENT':
    case 'EVENT1':
    case 'EVENT2':
    case 'EVENT3':
    case 'EVENT4':
      type = 'tv';
      break;
    case 'S07':
    case 'S17':
    case 'S08':
    case 'S18':
    case 'S19':
      type = 'radio';
      break;
    default:
      type = '24tv';
      break;
  }
  return type;
};

export const getLiveChannelTypeName = (channelId) => {
  let name;
  switch (channelId) {
    case 'S01':
    case 'S02':
    case 'S03':
    case 'S04':
    case 'S05':
    case 'S06':
    case 'S09':
    case 'S10':
    case 'S11':
    case 'S12':
    case 'S20':
    case 'EVENT':
    case 'EVENT1':
    case 'EVENT2':
    case 'EVENT3':
    case 'EVENT4':
      name = '라이브 TV';
      break;
    case 'S07':
    case 'S17':
    case 'S08':
    case 'S18':
    case 'S19':
      name = '라이브 라디오';
      break;
    default:
      name = '24시간 TV';
      break;
  }
  return name;
};

/** 채널아이디가 가상채널인지 확인.
 * @function
 * @param string
 * @returns {Boolean}
 */
export const isVirtualChannel = (channelId) => {
  switch (channelId) {
    case 'S01':
    case 'S02':
    case 'S03':
    case 'S04':
    case 'S05':
    case 'S06':
    case 'S07':
    case 'S08':
    case 'S09':
    case 'S10':
    case 'S11':
    case 'S12':
    case 'S17':
    case 'S18':
    case 'S19':
    case 'S20':
    case 'EVENT':
    case 'EVENT1':
    case 'EVENT2':
    case 'EVENT3':
    case 'EVENT4':
      return false;
    default:
      return true;
  }
};
/**
 * 편성표 API 조회
 * @function
 * @param string
 * @returns {String}
 */
export const toPlanUrl = (channelId) => {
  let result;
  switch (channelId) {
    case 'tv':
    case 'S01':
      result = `https://www.sbs.co.kr/schedule/index.html?type=tv&channel=SBS`;
      break;
    case 'S02':
      result = `https://www.sbs.co.kr/schedule/index.html?type=tv&channel=ESPN`;
      break;
    case 'S03':
      result = `https://www.sbs.co.kr/schedule/index.html?type=tv&channel=Plus`;
      break;
    case 'S04':
      result = `https://www.sbs.co.kr/schedule/index.html?type=tv&channel=ETV`;
      break;
    case 'S05':
      result = `https://www.sbs.co.kr/schedule/index.html?type=tv&channel=Golf`;
      break;
    case 'S12':
      result = `https://www.sbs.co.kr/schedule/index.html?type=tv&channel=Golf2`;
      break;
    case 'S06':
      result = `https://www.sbs.co.kr/schedule/index.html?type=tv&channel=CNBC`;
      break;
    case 'S07':
    case 'S17':
      result = `https://www.sbs.co.kr/schedule/index.html?type=ra&channel=Power`;
      break;
    case 'radio':
    case 'S08':
    case 'S18':
      result = `https://www.sbs.co.kr/schedule/index.html?type=ra&channel=Love`;
      break;
    case 'S09':
      result = `https://www.sbs.co.kr/schedule/index.html?type=tv&channel=MTV`;
      break;
    case 'S10':
      result = `https://www.sbs.co.kr/schedule/index.html?type=tv&channel=Nick`;
      break;
    case 'S11':
      result = `https://www.sbs.co.kr/schedule/index.html?type=tv&channel=Fil`;
      break;
    case 'S19':
      result = `https://www.sbs.co.kr/schedule/index.html?type=ra&channel=DMB+Radio`;
      break;
    default:
      result = `https://www.sbs.co.kr/schedule/index.html?div=pc_scheduler`;
      break;
  }
  return result;
};
/**
   * 채널별 편성표 API 조회.
   * @function
   * @param string
   * @param string
   * @returns {String}
   */
export const toPlanAPIUrl = (channelId, yyyy, mm, dd) => {
  let result;
  switch (channelId) {
    case 'S01':
      result = `${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE}/${yyyy}/${mm}/${dd}/SBS.json`;
      break;
    case 'S02':
      result = `${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE}/${yyyy}/${mm}/${dd}/ESPN.json`;
      break;
    case 'S03':
      result = `${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE}/${yyyy}/${mm}/${dd}/Plus.json`;
      break;
    case 'S04':
      result = `${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE}/${yyyy}/${mm}/${dd}/ETV.json`;
      break;
    case 'S05':
      result = `${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE}/${yyyy}/${mm}/${dd}/Golf.json`;
      break;
    case 'S09':
      result = `${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE}/${yyyy}/${mm}/${dd}/MTV.json`;
      break;
    case 'S12':
      result = `${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE}/${yyyy}/${mm}/${dd}/Golf2.json`;
      break;
    case 'S06':
      result = `${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE}/${yyyy}/${mm}/${dd}/CNBC.json`;
      break;
    case 'S07':
      result = `${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE}/${yyyy}/${mm}/${dd}/Power.json`;
      break;
    case 'S17':
      result = `${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE}/${yyyy}/${mm}/${dd}/Power.json`;
      break;
    case 'S08':
      result = `${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE}/${yyyy}/${mm}/${dd}/Love.json`;
      break;
    case 'S18':
      result = `${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE}/${yyyy}/${mm}/${dd}/Love.json`;
      break;
    case 'S09':
      result = `${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE}/${yyyy}/${mm}/${dd}/MTV.json`;
      break;
    case 'S10':
      result = `${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE}/${yyyy}/${mm}/${dd}/Nick.json`;
      break;
    case 'S11':
      result = `${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE}/${yyyy}/${mm}/${dd}/Fil.json`;
      break;
    case 'S19':
      result = `${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE}/${yyyy}/${mm}/${dd}/DMB+Radio.json`;
      break;
    default:
      result = null;
      break;
  }
  return result;
};


export const getMsg = (text) => {
  try {
    if (typeof text === 'string' && text.indexOf('{') > -1) {
      text = text.replace('"{', '{');
      text = text.replace('}"', '}');
      return JSON.parse(text).msg;
    } else {
      return text;
    }
  } catch (e) {
    return text;
  }

}
export const getImg = (text) => {
  try {
    if (typeof text === 'string' && text.indexOf('{') > -1) {
      text = text.replace('"{', '{');
      text = text.replace('}"', '}');
      if (JSON.parse(text).emoticon_pc !== undefined && JSON.parse(text).emoticon_pc.length > 0) {
        return decodeURIComponent(JSON.parse(text).emoticon_pc);
      } else if (JSON.parse(text).emoticon !== undefined && JSON.parse(text).emoticon.length > 0) {
        //잘못된 데이터이지만 노출함.
        return decodeURIComponent(JSON.parse(text).emoticon);
      } else {
        return null;
      }
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }

}


export const addDivParam = (link) => {
  const url = new URL(link, window.location.origin);
  if (!url.searchParams.has('div')) {
    url.searchParams.append('div', 'live_list');
  }
  return url.toString();
}; 

/**
 * 방송의 현재 진행률을 계산합니다.
 * @param {string} startTime - 방송 시작 시간 (형식: "HH:MM")
 * @param {string} endTime - 방송 종료 시간 (형식: "HH:MM")
 * @returns {number} 현재 진행률 (0-100 사이의 정수)
 */
export const calculateProgress = (startTime, endTime) => {
  const now = new Date();
  const [startHour, startMinute] = startTime.split(':').map(Number);
  const [endHour, endMinute] = endTime.split(':').map(Number);
  
  const start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), startHour, startMinute);
  const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), endHour, endMinute);
  
  // 종료 시간이 다음 날인 경우 (예: 23:00 ~ 01:00)
  if (end < start) {
    end.setDate(end.getDate() + 1);
  }
  
  if (now < start || now > end) return 0;
  
  const totalDuration = end - start;
  const elapsedTime = now - start;
  
  return Math.round((elapsedTime / totalDuration) * 100);
};